var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            [
              _vm.user.profile_picture
                ? _c("img", {
                    staticClass: "decision",
                    attrs: { src: _vm.user.profile_picture_url, alt: "" }
                  })
                : _c("a-avatar", {
                    staticClass: "user_icon",
                    attrs: { icon: "user" }
                  })
            ],
            1
          ),
          _c("a-col", [
            _c("span", { staticClass: "mrg-left" }, [
              _c("strong", [
                _vm.isFemme(_vm.user.sexe) && _vm.hasConj(_vm.user.nom_conj)
                  ? _c("span", [
                      _vm._v(
                        "\n            " + _vm._s(_vm.user.nom_conj) + " "
                      ),
                      _c("b", [_vm._v("née")]),
                      _vm._v(" " + _vm._s(_vm.user.full_name) + "\n          ")
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.user.full_name) +
                          " \n          "
                      )
                    ])
              ]),
              _vm._v("\n        né(e) le\n        "),
              _c("strong", [_vm._v(_vm._s(_vm._f("date")(_vm.user.dtn)))])
            ]),
            _c("br"),
            _c("br")
          ])
        ],
        1
      ),
      _c("a-icon", {
        staticStyle: { "margin-right": "10px" },
        attrs: { type: "phone" }
      }),
      _c("strong", [_vm._v("Téléphone")]),
      _c("br"),
      _vm._v("Personnel :\n  "),
      _c("strong", [_vm._v(_vm._s(_vm.user.tel_personnel_mobile))]),
      _c("br"),
      _vm._v("Fixe :\n  "),
      _c("strong", [_vm._v(_vm._s(_vm.user.tel_professionel_fixe))]),
      _c("br"),
      _vm._v("Professionnel :\n  "),
      _c("strong", [_vm._v(_vm._s(_vm.user.tel_professionel_mobile))]),
      _c("br"),
      _c("br"),
      _vm._v("\n  Date d'installation :\n  "),
      _c("strong", [_vm._v(_vm._s(_vm.date(_vm.user.dt_autorisation)))]),
      _c("br"),
      _c("br"),
      _c("a-icon", {
        staticStyle: { "margin-right": "10px" },
        attrs: { type: "mail" }
      }),
      _c("strong", [_vm._v(_vm._s(_vm.user.email))]),
      _c("br"),
      _c("br"),
      _vm.user.adresse_professionnelle_fr
        ? _c(
            "span",
            [
              _c("a-icon", {
                staticStyle: { "margin-right": "10px" },
                attrs: { type: "home" }
              }),
              _c("strong", [
                _vm._v(_vm._s(_vm.user.adresse_professionnelle_fr))
              ]),
              _c("br")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }