<template>
  <div>
    <a-row type="flex">
      <a-col>
        <img
          class="decision"
          v-if="user.profile_picture"
          :src="user.profile_picture_url"
          alt
        />
        <a-avatar icon="user" v-else class="user_icon" />
      </a-col>
      <a-col>
        <span class="mrg-left">
          <strong>
            <span v-if="isFemme(user.sexe) && hasConj(user.nom_conj)">
              {{ user.nom_conj }} <b>née</b> {{ user.full_name }}
            </span>
            <span v-else>
              {{ user.full_name }} 
            </span>
          </strong>
          né(e) le
          <strong>{{ user.dtn | date }}</strong>
        </span>
        <br />
        <br />
      </a-col>
    </a-row>
    <a-icon type="phone" style="margin-right : 10px" />
    <strong>Téléphone</strong>
    <br />Personnel :
    <strong>{{ user.tel_personnel_mobile }}</strong>
    <br />Fixe :
    <strong>{{ user.tel_professionel_fixe }}</strong>
    <br />Professionnel :
    <strong>{{ user.tel_professionel_mobile }}</strong>
    <br />
    <br />
    Date d'installation :
    <strong>{{ date(user.dt_autorisation) }}</strong>
    <br />
    <br />
    <a-icon type="mail" style="margin-right : 10px" />
    <strong>{{ user.email }}</strong>
    <br />
    <br />
    <span v-if="user.adresse_professionnelle_fr">
      <a-icon type="home" style="margin-right : 10px" />
      <strong>{{ user.adresse_professionnelle_fr }}</strong>
      <br />
    </span>
  </div>
</template>
<script>
export default {
  name: "UserDetails",
  props: { user: Object },
};
</script>
<style scoped>
.mrg-left {
  margin-left: 10px;
}
</style>
