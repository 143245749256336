<template>
  <a-select
    placeholder="Etat inscription"
    :allowClear="true"
     :defaultValue="selectedValue"
    @change="handleEtatInscriptionChange"
    style="width: 100%"
  >
    <a-select-option value="">Tous</a-select-option>
    <a-select-option v-for="etat in localEtats" :key="etat.key">
        {{ etat.value }}
    </a-select-option>
  </a-select>
</template>
<script>
export default {
  name: "EtatInscriptionList",
  props: { localEtats: Array, selectedValue : Number },
  methods: {
    handleEtatInscriptionChange(selectedItem) {
      this.$emit("etat_inscription_change", selectedItem);
    }
  }
};
</script>
