<template>
  <div>
    <h1 style="margin-left: 0%; margin-bottom: 4%">Etat des inscrits</h1>
    <ComposeMail
      ref="composeMail"
      :visible="emailModalVisible"
      :is-admin="true"
      :reply-to="replyTo"
      :isDestinationsSelectDisabled="true"
      :key="newMailComponentKey"
      @cancel="hideEmailModal"
      @create="handleCreate"
      @upload_success="handleUploadedFile($event)"
    />
    <a-spin :spinning="downloading">
      <AdherentDecision
        :user="selectedUser"
        :visible="decisionModalVisible"
        :processing="decisionProcessing"
        @close="handleDecisionFormClose"
        @submit="handleDecisionFormSubmit"
        :key="newDecisionComponentKey"
        ref="decisionForm"
      />
      <a-row type="flex" justify="start">
        <a-col>
          <a-button
            class="editable-add-btn"
            type="primary"
            @click="addNewAdherent"
            >Ajouter inscrit</a-button
          >
        </a-col>
        <a-col
          :xs="22"
          :sm="12"
          :md="8"
          :lg="6"
          :xxl="3"
          class="mrgin_btm margin_left"
        >
          <DocumentPrintingList
            :key="documentPrintingKey"
            :window="listeAdherentWindowsName"
            placeholder="Liste des adhérents"
            @handle_documents_printing="handleDocumentsPrinting($event)"
          />
        </a-col>
      </a-row>
      <a-row type="flex" justify="space-between">
        <a-col :xs="22" :sm="10" :md="7" :lg="4" class="mrgin_btm">
          <DocumentPrintingList
            :user="selectedUser"
            :key="documentPrintingKey"
            :window="windowsName"
            placeholder="Imprimer"
            @handle_documents_printing="handleDocumentsPrinting($event)"
          />
        </a-col>
        <a-col :xs="22" :sm="10" :md="7" :lg="3" class="mrgin_btm">
          <WilayasList
            :data="wilayas"
            :selected-value="+selected_wilaya_value"
            @wilaya_change="handleWilayaFilterChange($event)"
          />
        </a-col>
        <a-col :xs="22" :sm="10" :md="7" :lg="3" class="mrgin_btm">
          <EtatInscriptionList
            :localEtats="etatsAdherents"
            :selected-value="Number(selected_etat_value)"
            @etat_inscription_change="handleEtatInscriptionChange($event)"
          />
        </a-col>
        <a-col :xs="22" :sm="10" :md="7" :lg="3" class="mrgin_btm">
          <EtatSecteurActiviteList
            :localEtats="secteurs"
            :selected-value="Number(selected_secteur_value)"
            @etat_secteur_activite_change="handleSecteurActiviteChange($event)"
          />
        </a-col>
        <a-col :xs="22" :sm="10" :md="7" :lg="3" class="mrgin_btm">
          <EtatPaiementList
            :selected-value="selected_paiement_value"
            @etat_paiement_change="handlePaiementFilterChange($event)"
          />
        </a-col>
        <a-col :xs="22" :sm="10" :md="7" :lg="5" class="mrgin_btm txt-end">
          <a-auto-complete
            v-model="query"
            :data-source="filetredDataSource"
            placeholder="Rechercher"
            @select="onSelectAutoComplete"
            @search="onSearch"
          >
            <a-input-search
              class="search_input"
              placeholder="Rechercher"
              @search="fetchData"
              enterButton
            />
          </a-auto-complete>
        </a-col>
      </a-row>

      <a-spin :spinning="usersLoading">
        <a-table
          bordered
          v-if="adherents.length"
          :dataSource="adherents"
          :columns="columns"
          :rowKey="
            (record) => {
              return record.id;
            }
          "
          :rowSelection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            hideDefaultSelections: true,
            onSelect: onSelect,
          }"
          :pagination="false"
          @change="handleSorterChange"
        >
          <template slot="etat" slot-scope="text, record">
            <EtatInscriptionIndicator :etat-inscription="record.etat" />
          </template>
          <template slot="statut_prof" slot-scope="text, record">
            <StatuProfessionalIndicator
              :statut-professionnel="
                getStatuProfessionnel(record.etat_activites)
              "
            />
          </template>
          <template slot="secteur_activite" slot-scope="text, record">
            {{ get(record, "etat_activites[0].secteur.nom_fr") }}
          </template>
          <template slot="wilaya_professionnelle" slot-scope="text, record">
            {{ get(record, "wilaya_professionnelle.nom_fr") }}
          </template>

          <template slot="cotisation" slot-scope="text, record">
            <EtatPaiementIndicator
              :etat="record.etat"
              :balance="get(record, 'etat_paiement.balance', 1)"
            />
          </template>
          <template
            class="table-operation"
            slot="operation"
            slot-scope="text, record"
          >
            <a-tooltip placement="top">
              <template slot="title">
                <span>État activités</span>
              </template>
              <a-icon
                type="clock-circle"
                @click="goToAdherentEtatActivite(record.id)"
                class="action_icons"
                theme="twoTone"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Cotisation</span>
              </template>
              <a-icon
                type="dollar"
                @click="goToAdherentCotisation(record.id)"
                class="action_icons"
                theme="twoTone"
                :twoToneColor="
                  get(record, 'etat_paiement.balance', 1) >= 0
                    ? '#52c41a'
                    : '#eb2f96'
                "
              />
            </a-tooltip>
            <a-popconfirm
              v-if="adherents.length"
              title="Etes-vous sûr de vouloir supprimer cette ligne ?"
              @confirm="() => onDelete(record.id)"
            >
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Suppression</span>
                </template>
                <a-icon
                  type="delete"
                  class="action_icons"
                  theme="twoTone"
                  twoToneColor="#eb2f96"
                />
              </a-tooltip>
            </a-popconfirm>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Edition</span>
              </template>
              <a-icon
                type="edit"
                v-on:click="handleEdit(record.id)"
                class="action_icons"
                theme="twoTone"
                twoToneColor="#52c41a"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Décision</span>
              </template>
              <img
                class="decision"
                style="width: auto; height: auto"
                @click="showDecisionForm(record)"
                src="https://img.icons8.com/dotty/22/000000/test-partial-passed.png"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Demandes</span>
              </template>
              <a-icon
                type="form"
                class="action_icons"
                @click="showUserDemandes(record)"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Envoyer mail</span>
              </template>
              <a-icon
                type="message"
                v-on:click="sendEmail(record)"
                class="action_icons"
                theme="twoTone"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Demande Attestations</span>
              </template>
              <a-icon
                type="highlight"
                @click="showUserAttestations(record)"
                class="action_icons"
              />
            </a-tooltip>
          </template>
          <template slot="nom_prenom" slot-scope="text, record">
            <span v-bind:class="{ black: isUserEnAttente(record) }">
              <a-avatar
                v-if="record.profile_picture"
                slot="avatar"
                :src="record.profile_picture_url"
              />
              <a-avatar v-else>{{ record.nom_fr[0] }}</a-avatar>
              <a-badge status="processing" v-if="isUserEnAttente(record)" />
              <span v-if="isFemme(record.sexe) && hasConj(record.nom_conj)">
                {{ record.nom_conj }} <b>née</b> {{ record.full_name }}
              </span>
              <span v-else>
                {{ record.full_name }}
              </span>
            </span>
          </template>
          <!-- _________________________START___MATRICULE-->
          <template slot="matricule" slot-scope="text, record">
            <span v-bind:class="{ black: isUserEnAttente(record) }">
              <a-badge status="processing" v-if="isUserEnAttente(record)" />
              {{ record.matricule }}
            </span>
          </template>
          <!-- _________________________END___MATRICULE-->
          <!-- START Child Row -->
          <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
            <UserDetails :user="record" />
          </div>
          <!-- END Child Row -->
        </a-table>
        <EmptyResult v-else />
        <a-pagination
          v-if="adherents.length"
          style="margin-top: 1%"
          @change="onPaginationChange"
          @showSizeChange="onPageSizeChange"
          :current="pagination.current"
          :total="total"
          :showTotal="(total) => `Total : ${total}`"
          :pageSize="pagination.pageSize"
          :showSizeChanger="true"
        />
      </a-spin>
    </a-spin>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
// import SearchComponent from "@/components/common/Search";
import AdherentDecision from "./AdherentsDecision";
import constants from "@/const/const";
import ComposeMail from "@/components/common/messagerie/NewMail";
import EmptyResult from "@/components/common/EmptyResult";
// import EtatDossierList from "@/components/common/EtatDossierList";
import EtatInscriptionList from "@/components/common/EtatInscriptionList";
import WilayasList from "@/components/common/WilayasList";
import EtatPaiementList from "@/components/common/EtatPaiementList";
import StatuProfessionalIndicator from "@/components/common/StatuProfessionalIndicator";
import EtatPaiementIndicator from "@/components/common/EtatPaiementIndicator";
import EtatInscriptionIndicator from "@/components/common/EtatInscriptionIndicator";
import EtatSecteurActiviteList from "@/components/common/EtatSecteurActiviteList";
import DocumentPrintingList from "@/components/common/DocumentPrintingList";
import UserDetails from "./UserDetails";
import debounce from "lodash-es/debounce";

import auth from "@/services/auth";

const ADHERENTS_URL = `/admin/adherents/edit/`;
const CREATE_USER_URL = "/admin/adherents/create";
 

export default {
  name: "AdherentsList",
  created() {
    this.fetchData();
    this.checkForNewMessagesEntries();
    this.checkForDemandesAttestationEntries();
    this.checkForNewPaiementsEntries();
    this.checkForDemandesOuverturesEntries();
    this.checkForNewUsersCount();
    this.getHelperData();
  },
  mounted() {

  },
  components: {
    // SearchComponent,
    AdherentDecision,
    ComposeMail,
    EmptyResult,
    // EtatDossierList,
    EtatInscriptionList,
    EtatPaiementList,
    StatuProfessionalIndicator,
    EtatInscriptionIndicator,
    UserDetails,
    EtatPaiementIndicator,
    EtatSecteurActiviteList,
    DocumentPrintingList,
    WilayasList,
  },
  data() {
    this.onSearch = debounce(this.onSearch, 1000);
    return {
      selectedUser: null,
      filetredDataSource: [],
      q: "",
      selected_sorting_value: "asc",
      index: 0,
      usersLoading: true,
      downloading: false,
      emailModalVisible: false,
      replyTo: null,
      replyToId: null,
      documentPrintingKey: 0,
      newMailComponentKey: 0,
      newDecisionComponentKey: 0,
      windowsName: "etat inscrits",
      listeAdherentWindowsName: "liste adherents",
      decisionModalVisible: false,
      decisionProcessing: false, 
      newEntriesTimer: 1000 * 30,
      selectedRowKeys: [],
      // sortedInfo: null,
      options: [
        {
          value: "15",
          label: "Zhejiang",
        },
        {
          value: "d",
          label: "Jiangsu",
          children: [
            {
              value: "e",
              label: "Nanjing",
              children: [
                {
                  value: "f",
                  label: "Zhong Hua Men",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    columns() {
      // let { sortedInfo } = this;
      // // sortedInfo = sortedInfo || {};
      const columns = [
        {
          key: "nom_fr",
          title: "Nom & Prénom",
          scopedSlots: { customRender: "nom_prenom" },
        },
        {
          title: "Secteur activité",
          scopedSlots: { customRender: "secteur_activite" },
        },
        {
          title: "Wilaya Pro.",
          scopedSlots: { customRender: "wilaya_professionnelle" },
        },
        {
          title: "Etat",
          scopedSlots: { customRender: "etat" },
        },
        {
          title: "Statut professionnel",
          scopedSlots: { customRender: "statut_prof" },
        },
        {
          title: "Paiement",
          scopedSlots: { customRender: "cotisation" },
        },
        {
          title: "Actions",
          scopedSlots: { customRender: "operation" },
        },
      ];
      return columns;
    },
    ...mapGetters({
      selected_etat_value: "getSelectedEtatValue",
      selected_paiement_value: "getSelectedPaiementValue",
      selected_secteur_value: "getSelectedSecteurValue",
      selected_wilaya_value: "getSelectedWilayaValue",
      query: "getSelectedSearchValue",
    }),
    ...mapState({
      adherents: (state) => state.adherents.adherents,
      total: (state) => state.adherents.pagination.total,
      pagination: (state) => state.adherents.pagination,
      etatsAdherents: (state) => state.adherents.etatsAdherents,
      wilayas: (state) => state.wilayas.organisation_wilayas,
      secteurs: (state) => state.secteurs.secteurs,
      organisation: (state) => state.organisation.organisation,
    }),
  },
  methods: {
    ...mapMutations({
      setSelectedEtatValue: "setSelectedEtatValue",
      setSelectedPaiementValue: "setSelectedPaiementValue",
      setSelectedSecteurValue: "setSelectedSecteurValue",
      setSelectedWilayaValue: "setSelectedWilayaValue",
      setSelectedSearchValue: "setSelectedSearchValue",
    }),
    onSearch(searchText) {
      this.setSelectedSearchValue(searchText)
      auth
        .axios()
        .get(`${auth.getBaseUrl()}/admin/user/search`, {
          params: {
            q: searchText,
          },
        })
        .then((response) => (this.filetredDataSource = response.data))
        // eslint-disable-next-line no-console
        .catch(() => console.warn("Error"))
    },
    onSelectAutoComplete(value) {
      this.filetredDataSource = [];
      this.setSelectedSearchValue(value)
      this.fetchData();
    },
    showUserDemandes(user) {
      this.$router.push(`adherents/demandes/edit/${user.id}`);
    },
    showUserAttestations(user) {
      this.$router.push(`adherents/attestations/${user.id}`);
    },
    handleWilayaFilterChange(selectedItem) {
      this.setSelectedWilayaValue(selectedItem);
    },
    handleSecteurActiviteChange(selectedItem) {
      this.setSelectedSecteurValue(selectedItem);
    },
    handleSorterChange(pagination, filters, sorter) {
      // this.sortedInfo = sorter;
      this.setNameSorter(sorter.order);
      this.resetPagination();
      this.fetchData();
    },
    getStatuProfessionnel(etatActivites) {
      return this.get(etatActivites, "[0].statut_professionnel");
    },
    getSecteurActvite(etatActivites) {
      return this.get(etatActivites, "[0].secteur_activite");
    },
    fetchData() {
      this.startUsersLoading();
      this.getAdditioanlData(this.getParameters()).finally(() =>
        this.stopUsersLoading()
      );
    },
    onPaginationChange(pagination) {
      this.setPagination({ ...this.pagination, current: pagination });
      this.fetchData();
    },
    onPageSizeChange(current, size) {
      this.setPagination({ ...this.pagination, pageSize: size });
      this.fetchData();
    },
    getParameters() {
      return {
        etat: this.selected_etat_value,
        paiement: this.selected_paiement_value,
        wilaya: this.selected_wilaya_value,
        secteur_activite: this.selected_secteur_value,
        sort_name: this.selected_sorting_value,
        q: this.query,
        per_page: this.pagination.pageSize,
        page: this.pagination.current,
      };
    },
    getHelperData() {
      this.$store.cache.dispatch("fetchExercices");
      this.$store.cache.dispatch("fetchWilayas");
      this.$store.cache.dispatch("fetchOrganisation");
      this.$store.cache.dispatch("fetchOrganisationWilayas");
      this.$store.cache.dispatch("fetchSpecialites");
      this.$store.cache.dispatch("fetchCommunes");
      this.$store.cache.dispatch("fetchDiplomes");
      this.$store.cache.dispatch("fetchSecteurs");
    },
    handleUploadedFile($event) {
      this.addAttachementFile($event);
    },
    handleCreate() {
      const form = this.$refs.composeMail.form;
      let that = this;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        let email = {};
        email.destination = [];
        email.attachements = that.getAttachementFiles();
        email.subject = values.subject;
        email.message = values.message;
        email.destination.push(this.replyToId);
        this.send(email)
          .then(() => {
            that.$_showSuccessMessage(`${constants.MESSAGE_SENT}`, 5);
            this.hideEmailModal();
            that.replyToId = null;
            form.resetFields();
          })
          .catch(() => {
            that.$_throwAnError(`${constants.MESSAGE_SENT_FAIL}`, 5);
          });
      });
    },
    setNameSorter(order) {
      this.selected_sorting_value = order === "ascend" ? "asc" : "desc";
    },
    sendEmail(adherant) {
      this.replyTo = adherant.email;
      this.replyToId = adherant.id;
      this.genererateNewPaiementEditorKey();
      this.showEmailModal();
    },
    genererateNewPaiementEditorKey() {
      this.newMailComponentKey = this.getRandomInt();
    },
    genererateNewDecisionEditorKey() {
      this.newDecisionComponentKey = this.getRandomInt();
    },
    genererateDocumentPrintingKey() {
      this.documentPrintingKey = this.getRandomInt();
    },
    onSelectChange(selectedRowKeys) {
      // Preventing multiple selection
      if (selectedRowKeys.length > 1) {
        this.$_throwAnError(
          " Vous devez d’abord désélectionner l’utilisateur  "
        );
        return;
      }
      this.selectedRowKeys = selectedRowKeys;
    },
    onSelect(record, selected) {
      if (selected) {
        this.selectedUser = record;
        return;
      }
      this.selectedUser = null;
    },
    filterUsers() {
      this.resetPagination();
      this.fetchData();
    },
    addNewAdherent() {
      this.clearSelectedAdherent();
      this.$router.push(CREATE_USER_URL);
    },
    handleDecisionFormSubmit() {
      this.startDecisionFormProcessing();
      this.$refs["decisionForm"].form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (this.get(values, "dt_comission")) {
            values["dt_comission"] = this.formatDate(
              values["dt_comission"],
              "YYYY-MM-DD"
            );
          }
          this.updateEtatConfirmation({
            id: this.selectedUser.id,
            ...values,
          })
            .then(() => {
              this.handleDecisionFormClose();
              this.resetDecisionFormFields();
              this.clearDecisionFormSelectedAdherent();
              this.fetchData();
            })
            .catch(() => {
              this.stopDecisionProcessing();
            });
        } else {
          this.stopDecisionProcessing();
        }
      });
    },
    hideEmailModal() {
      this.emailModalVisible = false;
    },
    showEmailModal() {
      this.emailModalVisible = true;
    },
    handleDecisionFormClose() {
      this.closeDecisionModal();
      this.stopDecisionProcessing();
    },
    showDecisionForm(user) {
      if (this.isUserNonSoumis(user)) {
        this.$_throwAnError("L'adhérent n'a pas encore soumis son dossier !");
        return;
      }
      this.genererateNewDecisionEditorKey();
      this.setDecisionFormSelectedAdherent(user);
      this.openDecisionModal();
    },
    stopDecisionProcessing() {
      this.decisionProcessing = false;
    },
    startDecisionFormProcessing() {
      this.decisionProcessing = true;
    },
    clearDecisionFormSelectedAdherent() {
      this.selectedUser = null;
    },
    setDecisionFormSelectedAdherent(user) {
      this.selectedUser = user;
    },
    resetDecisionFormFields() {
      this.$refs["decisionForm"].form.resetFields();
    },
    goToAdherentCotisation(userId) {
      this.$router.push(`adherents/cotisation/edit/${userId}`);
    },
    goToAdherentEtatActivite(userId) {
      this.$router.push(`adherents/etat-activites/edit/${userId}`);
    },
    handleEtatInscriptionChange(selectedEtat) {
      this.setSelectedEtatValue(selectedEtat);
    },
    handlePaiementFilterChange(selectedItem) {
      this.setSelectedPaiementValue(selectedItem);
    },

    handleDocumentsPrinting(value) {
      if (value) {
        let params = this.selectedRowKeys[0];
          this.startUsersLoading();
          this.callPdfGenerator({
              id: params ? params : 1,
              type: value[0],
              exercice: value[1],
              organisation: this.organisation.id,
          })
              .catch(() => this.$_throwAnError())
              .finally(() => this.stopUsersLoading());
      }
    },
    openDecisionModal() {
      this.decisionModalVisible = true;
    },
    closeDecisionModal() {
      this.decisionModalVisible = false;
    },
    startUsersLoading() {
      this.usersLoading = true;
    },
    stopUsersLoading() {
      this.usersLoading = false;
    },
    onDelete(key) {
      this.remove(key).then(() => this.fetchData());
    },
    checkForNewPaiementsEntries() {
      if (!Window.newPaiementsEntriesTimeout) {
        Window.newPaiementsEntriesTimeout = setTimeout(() => {
          this.getNewPaiementsCount();
        }, this.newEntriesTimer);
      }
    },
    checkForNewMessagesEntries() {
      if (!Window.newMessagesEntriesTimeout) {
        Window.newMessagesEntriesTimeout = setTimeout(() => {
          this.getUnreadMessagesCount();
        }, this.newEntriesTimer);
      }
    },
    checkForDemandesOuverturesEntries() {
      if (!Window.newDemandesOuverturesEntriesTimeout) {
        Window.newDemandesOuverturesEntriesTimeout = setTimeout(() => {
          this.getNewDemandeOuverturesCount();
        }, this.newEntriesTimer);
      }
    },
    checkForDemandesAttestationEntries() {
      if (!Window.newDemandesAttestationEntriesTimeout) {
        Window.newDemandesAttestationEntriesTimeout = setTimeout(() => {
          this.getNewDemandeAttestationCount();
        }, this.newEntriesTimer);
      }
    },
    checkForNewUsersCount() {
      if (!Window.newAdherentsEntriesTimeout) {
        Window.newAdherentsEntriesTimeout = setTimeout(() => {
          this.getNewUsersCount();
        }, this.newEntriesTimer);
      }
    },
    handleEdit(id) {
      this.$router.push(`${ADHERENTS_URL}${id}`);
    },
    ...mapMutations({
      clearSelectedAdherent: "clearSelectedAdherent",
      setPagination: "setPagination",
      resetPagination: "setDefaultPagination",
      addAttachementFile: "addAttachementsFileId",
    }),
    ...mapGetters({ getAttachementFiles: "getAttachementsIds" }),
    ...mapActions({
      getAdditioanlData: "fetchAdminAdherents",
      remove: "destroyAdminAdherent",
      getReceivedMessages: "fetchReceivedMessages",
      getUnreadMessagesCount: "fetchUnreadMessagesCount",
      getNewDemandeAttestationCount: "fetchNewDemandeAttestationCount",
      getNewDemandeOuverturesCount: "fetchNewDemandeOuverturesCount",
      getNewPaiementsCount: "fetchNewPaiementsCountCount",
      getNewUsersCount: "fetchNewَAdherents",
      updateEtatConfirmation: "updateAdherentConfirmation",
      callPdfGenerator: "callPdfGenerator",
      send: "sendMessage",
    }),
  },
};
</script>
<style>
.mrgin_btm {
  margin-bottom: 15px;
}
.decision {
  color: #009688;
  cursor: pointer;
  text-decoration-line: underline;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 10px 16px 10px;
  padding-top: 16px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-left: 10px;
}
.decision {
  border-radius: 50%;
  width: 84px;
  height: 84px;
  margin-right: 10px;
}
td {
  width: max-content;
}
.margin_left {
  margin-left: 1%;
}
.decision {
  text-decoration: underline;
  margin-left: 3%;
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
.editable-add-btn {
  margin-bottom: 20px !important;
}
.action_icons {
  margin: 4px;
  font-size: large;
}
.txt-end {
  text-align: end;
}
.search_input {
  width: 100%;
}
</style>
