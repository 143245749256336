var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.etat === _vm.ETAT_ADHERANT_ACCEPTER
      ? _c("span", [
          _vm.isPayeAjour(_vm.balance)
            ? _c("span", { staticStyle: { color: "#52c41a" } }, [
                _vm._v("Ajour")
              ])
            : _c("span", { staticStyle: { color: "#eb2f96" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("currency")(_vm.balance)) +
                    "\n    "
                )
              ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }