<template>
  <AdherentsList />
</template>

<script>
import AdherentsList from "./AdherentsList";
export default {
  name: "AdherentsIndex",
  components: { AdherentsList },
};
</script>