var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        placeholder: "Etat paiement",
        allowClear: true,
        defaultValue: _vm.selectedValue
      },
      on: { change: _vm.handlePaiementChange }
    },
    [
      _c("a-select-option", { attrs: { value: "" } }, [_vm._v("Tous")]),
      _c("a-select-option", { key: 1, attrs: { value: 1 } }, [_vm._v("Payé")]),
      _c("a-select-option", { key: 2, attrs: { value: 2 } }, [
        _vm._v("Non Payé")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }