<template>
  <span>
    <span v-if="etat === ETAT_ADHERANT_ACCEPTER">
      <span
        v-if="isPayeAjour(balance)"
        style="color:#52c41a"
        >Ajour</span
      >
      <span v-else style="color:#eb2f96">
        {{balance | currency}}
      </span>
    </span>
  </span>
</template>
<script>
export default {
  name: "EtatPaiementIndicator",
  props: { balance: Number, etat : Number }
};
</script>
