<template>
  <a-select
    placeholder="Etat paiement"
    :allowClear="true"
    :defaultValue="selectedValue"
    @change="handlePaiementChange"
    style="width: 100%"
  >
    <a-select-option value="">Tous</a-select-option>
    <a-select-option :key="1" :value="1">Payé</a-select-option>
    <a-select-option :key="2" :value="2">Non Payé</a-select-option>
  </a-select>
</template>
<script>
export default {
  name: "EtatPaiementList",
  props: { selectedValue: { type : Number }},
  methods: {
    handlePaiementChange(selectedItem) {
      this.$emit("etat_paiement_change", selectedItem);
    }
  }
};
</script>
